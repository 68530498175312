import { request } from '../http';

const authenticationService = {
  login: (User, Password) => {
    return request.post('/login', { User, Password });
  },
  logout: () => {
    return request.post('/Logout');
  }
}

export {
  authenticationService
}