import axios from 'axios';
import config from '../environment.json';

const request = axios.create({
  baseURL: config['API_URL'],
  headers: {
    'Content-Type': 'application/json'
  }
});

export { request }