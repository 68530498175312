import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useStateValue } from '../../state';
import { authenticationService } from '../../services/auth';
import './index.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import personIcon from '../../assets/images/personIcon.png';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

const Login = (props) => {

    const [{ authentication }, dispatch] = useStateValue();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState('');

    const handleClick = async () => {

        try {

            if (password && user) {
                await authenticationService.login(user, password).then(response => {

                    if (response.status == 201) {
                        localStorage.setItem('userId', user);
                        const tipoAcesso = 3;
                        localStorage.setItem('password', password);
                        localStorage.setItem('tipoAcesso', tipoAcesso);

                        dispatch({ type: 'login', value: user });

                        setMessage('Seja Bem vindo!')

                    }
                }).catch(error => {
                    if (error.response) {
                        setMessage(error.response.data)
                    }
                });
            } else {
                setMessage('Campos de Login nao preenchidos!')
            }

            setShow(true);
            setTimeout(() => { setShow(false); }, 7000);

        } catch (error) {

        }

    };

    return (
        !authentication ?
            <Container fluid align="right" className="login">
                <div className="Alert">
                    <Alert show={show} variant="info" dismissible onClose={() => setShow(false)}>
                        {message}
                    </Alert>
                </div>
                <Form>

                    <div className="container">
                        <Card style={{ width: '18rem', padding: '10px;10px', textAlign: 'center' }} className="mb-2"  >
                            <Card.Header align="right">
                                <Card.Img variant="bottom" src={personIcon} style={{ width: '12em' }} />
                            </Card.Header>
                            <Card.Body>
                                <Form.Control type="email" placeholder="Enter email" className="mb-3" value={user} onChange={e => setUser(e.target.value)} />

                                <Form.Control type="password" placeholder="Password" className="mb-3" value={password} onChange={e => setPassword(e.target.value)} />

                                <Button onClick={handleClick} style={{ width: '15em' }} className="mb-3">Entrar</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </Form>
            </Container>
            :
            <Redirect to='/' />
    );
}

export default Login;