import { request } from '../http';

const partnersService = {
    get: () => {
        return request.get('/partner');
    },
    getById: (id) => {
        return request.get(`/partner/${id}`);
    },
    post: (body) => {
        return request.post('/partner', body);
    },
    patch: (body, id) => {
        return request.patch(`/partner/${id}`, body);
    }
    ,
    patchStatus: (body, id) => {
        return request.post(`/partner/${id}`, body);
    }
}

export {
    partnersService
}