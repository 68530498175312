import React, { useState } from "react";
import { patientsService } from '../../services/patients';
import { partnersService } from '../../services/partners';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import Stack from 'react-bootstrap/Stack';
const acesso = localStorage.getItem("tipoAcesso");
class PartientManage extends React.Component {

    state = {
        datatable: [],
        partnerList: [],
        message: '',
        show: false,
        modalShow: false,
        idPacientes: '',
        nome: '',
        datanascimento: '',
        rg: '',
        cpf: '',
        idParceiro: '',
        nomeParceiro: '',
        status: '',
        entrada: '',
        nomeFiltro: '',
        saida: '',
        currentPage: 1,
        postPerPage: 20,
        pages: []
    };


    componentDidMount() {
        this.fetch();
    }

    filter() {
        if (this.state.nomeFiltro) {
            let page = [];
            let filtro = this.state.nomeFiltro;
            let response = this.state.datatable.filter(x => x.nome.includes(filtro));

            for (let index = 1; index < Math.ceil(response.length / this.state.postPerPage); index++) {
                page.push(
                    <Pagination.Item key={index} onClick={() => this.setState({ currentPage: index })} >
                        {index}
                    </Pagination.Item>
                );
            }

            this.setState({
                datatable: response.sort(function (a, b) {
                    if (a.nome < b.nome) {
                        return -1;
                    }
                    if (a.nome > b.nome) {
                        return 1;
                    }
                    return 0;
                }),
                pages: page
            });
        } else {
            this.fetch();
        }
    }

    fetch = async () => {
        if (acesso < 2)
            window.open('/', "_self");

        partnersService.get().then(response => {
            if (response.status == 201) {


                this.setState({
                    partnerList: response.data


                });

            } else {
                this.setState({
                    show: true,
                    message: 'Não foram encontrados Parceiros para Vinculo!'
                });

            }

            setTimeout(() => { this.setState({ show: false }) }, 7000);

        });

        patientsService.get().then(response => {
            if (response.status == 201) {
                let page = [];
                for (let index = 1; index < Math.ceil(response.data.length / this.state.postPerPage); index++) {
                    page.push(
                        <Pagination.Item key={index} onClick={() => this.setState({ currentPage: index })} >
                            {index}
                        </Pagination.Item>
                    );
                }

                this.setState({
                    datatable: response.data.sort(function (a, b) {
                        if (a.nome < b.nome) {
                            return -1;
                        }
                        if (a.nome > b.nome) {
                            return 1;
                        }
                        return 0;
                    }),
                    pages: page
                });

            } else {
                return;
            }
            setTimeout(() => { this.setState({ show: false }) }, 7000);

        }).catch((response) => {

            this.setState({ show: true, message: response.data.responseResult });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        });

    };

    openModal() {


        this.setState({
            modalShow: true
        });

    }

    cancelEdit() {
        try {
            this.setState({
                id: '',
                userName: '',
                passWord: '',
                email: '',
                hourValue: 0,
                sobrenome: '',
                tel: '',
                tipo: '1',
                modalShow: false
            });

            this.fetch();
        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    saveOrUpdate() {
        try {
            let { idPacientes,
                nome,
                datanascimento,
                rg,
                cpf,
                idParceiro,
                entrada,
                saida
            } = this.state;

            let body =
            {
                idPacientes,
                nome,
                datanascimento,
                rg,
                cpf,
                idParceiro,
                status: 'Y',
                entrada,
                saida
            };

            if (idPacientes) {
                patientsService.patch(body, idPacientes).then(response => {

                    this.setState({ show: true, message: response.data, modalShow: false });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                    this.fetch()
                }).catch(response => {

                    this.setState({ show: true, message: response.data.responseResult });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });
            } else {
                patientsService.post(body).then(response => {

                    this.setState({ show: true, message: response.data, modalShow: false });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                    this.fetch()
                }).catch(response => {

                    this.setState({ show: true, message: response.data.responseResult });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });
            }

        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    changeUserStatus(id, status) {

        try {

            patientsService.patchStatus({ Status: status ? 'Y' : 'N', Id: id }, id).then(response => {

                let objIndex = this.state.datatable.findIndex(obj => obj.idPacientes == id);

                let data = this.state.datatable;

                data[objIndex].status = status ? 'Y' : 'N';

                this.setState({ show: true, message: response.data, datatable: data });
                setTimeout(() => { this.setState({ show: false }) }, 7000);
            }).catch(response => {

                this.setState({ show: true, message: response.toString() });
                setTimeout(() => { this.setState({ show: false }) }, 7000);
            });


        } catch (error) {
            this.setState({ show: true, message: error.toString() });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }

    }
    edit(data) {
        try {

            let { Nome, Sobrenome } = this.state.partnerList.filter(x => x.idUsuario === data.idParceiro)[0]

            let dateConverted = moment(data.datanascimento.replaceAll('/', '-'), 'DD-MM-YYYY').toDate();
            this.setState({
                idPacientes: data.idPacientes,
                nome: data.nome,
                nomeParceiro: `${Nome} ${Sobrenome}`,
                datanascimento: moment(dateConverted).format('YYYY-MM-DD'),
                rg: data.rg,
                cpf: data.cpf,
                idParceiro: data.idParceiro,
                entrada: data.entrada,
                saida: data.saida,
                modalShow: true
            });

        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    parseArray(datatable) {
        try {
            let converted = [];

            for (var prop in datatable) {
                converted.push({ prop: prop, value: datatable[prop] });
            }

            return converted;

        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
            return null;
        }
    }

    render() {

        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                const [value, setValue] = useState('');

                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <Form.Control
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Filtrar"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                        <ul className="list-unstyled">
                            {React.Children.toArray(children).filter(
                                (child) =>
                                    !value || child.props.children.toLowerCase().startsWith(value),
                            )}
                        </ul>
                    </div>
                );
            },
        );

        const { datatable, currentPage, postPerPage, pages, idParceiro, nomeParceiro } = this.state;
        const indexOfLastPost = currentPage * postPerPage;
        const indexOfFirstPost = indexOfLastPost - postPerPage;
        const currentData = datatable.slice(indexOfFirstPost, indexOfLastPost);

        const nextPage = () => this.setState({ currentPage: currentPage + 1 });
        const prevPage = () => this.setState({ currentPage: currentPage - 1 });

        return (

            <Container fluid align="right" >
                <div className="Alert">
                    <Alert show={this.state.show} variant="info" dismissible onClose={() => this.setState({ show: false })}>
                        {this.state.message}
                    </Alert>
                </div>
                <Form>
                    <Row style={{ textAlign: "center", padding: 5 }}><h2>Lista de Pacientes</h2></Row>
                    <Row style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                        <Stack direction="horizontal" gap={3}>

                            <Form.Control style={{ width: 300 }} type="text" placeholder="Nome Paciente" value={this.state.nomeFiltro}
                                onChange={item => this.setState({ nomeFiltro: item.target.value })} />

                            <Button variant="success" onClick={() => { this.filter() }} style={{ marginRight: 5 }}>
                                Filtrar
                            </Button>

                            <Button variant="danger" onClick={() => { this.fetch() }}>
                                Limpar
                            </Button>

                        </Stack>
                    </Row>
                    <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                        <Col>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr key={Math.random().toString(36).substring(2, 7)}>

                                        <th key={Math.random().toString(36).substring(2, 7)}>ID</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Nome</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Data Nascimento</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>RG</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>CPF</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Parceiro</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Status</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Entrada</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Saída</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Habilitar</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData && currentData.length > 0 ? (currentData).map(y => {
                                        return <tr key={Math.random().toString(36).substring(2, 7)}>

                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.idPacientes}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.nome}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.datanascimento}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.rg}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.cpf}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.idParceiro}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.status == 'Y' ? 'Ativo' : 'Inativo'}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.entrada}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.saida}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={y.status == 'Y' ? true : false}
                                                    key={Math.random().toString(36).substring(2, 7)}
                                                    onChange={item => this.changeUserStatus(y.idPacientes, item.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                <Button variant="primary" onClick={() => { this.edit(y) }}>
                                                    Editar
                                                </Button>
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10, padding: 3 }}>
                        <Col>
                            <Pagination>
                                <Pagination.Prev onClick={prevPage} />
                                <br />
                                {pages}
                                <br />
                                <Pagination.Next onClick={nextPage} />
                            </Pagination>
                        </Col>
                        <Col>
                            <Button variant="primary" href="/">
                                Voltar
                            </Button>
                            <Button variant="success" style={{ marginLeft: 10 }} onClick={() => { this.openModal(); }}>
                                Adicionar
                            </Button>
                        </Col>
                    </Row>

                </Form>



                <Modal show={this.state.modalShow} onHide={() => { this.setState({ modalShow: false }); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Paciente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control readOnly style={{ marginTop: 10 }} value={this.state.idPacientes} type="text" placeholder="ID" />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.nome} type="text" placeholder="Nome" onChange={item => this.setState({ nome: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" value={this.state.datanascimento} type="date" placeholder="Data Nascimento" onChange={item => this.setState({ datanascimento: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.rg} type="text" placeholder="RG" onChange={item => this.setState({ rg: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.cpf} type="text" placeholder="CPF" onChange={item => this.setState({ cpf: item.target.value })} />
                        <Dropdown onSelect={(id, name) => this.setState({ nomeParceiro: name.target.text, idParceiro: id })} style={{ marginTop: 10 }} placeholder="Parceiro" >
                            <Dropdown.Toggle id="dropdown-custom-components" style={{ width: '100%' }}>
                                {`${idParceiro} - ${nomeParceiro}`}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu}  >
                                {this.state.partnerList && this.state.partnerList.map(x => {
                                    return <Dropdown.Item eventKey={x.idUsuario}>{`${x.Nome} ${x.Sobrenome}`}</Dropdown.Item>
                                })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control style={{ marginTop: 10 }} value={this.state.entrada} type="time" placeholder="Entrada" onChange={item => this.setState({ entrada: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.saida} type="time" placeholder="Saida" onChange={item => this.setState({ saida: item.target.value })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.cancelEdit() }}>
                            Voltar
                        </Button>
                        <Button variant="success" onClick={() => { this.saveOrUpdate(this.state.id) }}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >

        );
    }
}

export default PartientManage;