import React from "react";
import { partnersService } from '../../services/partners';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';

const acesso = localStorage.getItem("tipoAcesso");
class PartnersManage extends React.Component {

    state = {
        datatable: [],

        message: '',
        show: false,
        modalShow: false,
        id: "",
        userName: "",
        passWord: "",
        email: "",
        hourValue: 0,
        sobrenome: "",
        tel: "",
        tipo: '1',
        page: 1,
        postPerPage: 10,
        pages: []

    };


    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {

        if (acesso < 3)
            window.open('/', "_self");

        partnersService.get().then(response => {
            if (response.status == 201) {


                let page = [];
                for (let index = 1; index < Math.ceil(response.data.length / this.state.postPerPage); index++) {
                    page.push(
                        <Pagination.Item key={index} onClick={() => this.setState({ page: index })} >
                            {index}
                        </Pagination.Item>
                    );
                }

                this.setState({
                    datatable: response.data,
                    message: 'Busca concluida',
                    pages: page
                });


            } else {
                return;
            }
            setTimeout(() => { this.setState({ show: false }) }, 7000);

        }).catch((response) => {

            this.setState({ show: true, message: response.data.responseResult });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        });

    };

    cancelEdit() {
        try {
            this.setState({
                id: '',
                userName: '',
                passWord: '',
                email: '',
                hourValue: 0,
                sobrenome: '',
                tel: '',
                tipo: '1',
                modalShow: false
            });

            this.fetch();
        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    saveOrUpdate() {
        try {


            let { id,
                userName,
                passWord,
                email,
                hourValue,
                sobrenome,
                tel,
                tipo
            } = this.state;

            const pass = passWord.indexOf(' ') >= 0;

            if (pass)
                throw new Error('Senha com espaços em branco');

            let body =
            {
                id,
                userName,
                passWord,
                email,
                hourValue,
                sobrenome,
                tel,
                tipo
            };

            if (id) {
                partnersService.patch(body, id).then(response => {

                    this.setState({ show: true, message: response.data.code || 'Atualizado com Sucesso !' });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                }).catch(response => {

                    this.setState({ show: true, message: 'Erro ao atualizar, tente mais tarde!' });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });
            } else {
                partnersService.post(body).then(response => {

                    this.setState({ show: true, message: response.data });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);

                }).catch(response => {

                    this.setState({ show: true, message: 'Erro ao inserir, tente mais tarde!' });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });
            }

        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    changeUserStatus(id, status) {

        try {

            partnersService.patchStatus({ Status: status ? 'Y' : 'N', Id: id }, id).then(response => {

                let objIndex = this.state.datatable.findIndex(obj => obj.idUsuario == id);

                let data = this.state.datatable;

                data[objIndex].Ativo = status ? 'Y' : 'N';

                this.setState({ show: true, message: response.data, datatable: data });
                setTimeout(() => { this.setState({ show: false }) }, 7000);
            }).catch(response => {

                this.setState({ show: true, message: response.toString() });
                setTimeout(() => { this.setState({ show: false }) }, 7000);
            });


        } catch (error) {
            this.setState({ show: true, message: error.toString() });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }

    }
    edit(data) {

        partnersService.getById(data.idUsuario).then(response => {
            if (response.status == 201) {
                const res = response.data[0];

                this.setState({
                    id: res.idUsuario,
                    userName: res.Nome,
                    passWord: res.Senha,
                    email: res.Email,
                    hourValue: res.ValorHora,
                    sobrenome: res.Sobrenome,
                    tel: res.Telefone,
                    modalShow: true
                });


            } else {
                return;
            }
            setTimeout(() => { this.setState({ show: false }) }, 7000);

        }).catch((response) => {

            this.setState({ show: true, message: response.data.responseResult });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        });

    }

    parseArray(datatable) {
        try {
            let converted = [];

            for (var prop in datatable) {
                converted.push({ prop: prop, value: datatable[prop] });
            }

            return converted;

        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
            return null;
        }
    }

    render() {

        const { datatable, page, postPerPage, pages } = this.state;
        const indexOfLastPost = page * postPerPage;
        const indexOfFirstPost = indexOfLastPost - postPerPage;
        const currentData = datatable.slice(indexOfFirstPost, indexOfLastPost);

        const nextPage = () => this.setState({ page: page + 1 });
        const prevPage = () => this.setState({ page: page - 1 });

        return (

            <Container fluid align="right" >
                <div className="Alert">
                    <Alert show={this.state.show} variant="info" dismissible onClose={() => this.setState({ show: false })}>
                        {this.state.message}
                    </Alert>
                </div>
                <Form>
                    <Row style={{ textAlign: "center", padding: 5 }}><h2>Lista de Usuarios</h2></Row>
                    <Row style={{ textAlign: "center", padding: 5 }}><h2>Filtros</h2></Row>
                    <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                        <Col>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr key={Math.random().toString(36).substring(2, 7)}>

                                        <th key={Math.random().toString(36).substring(2, 7)}>ID</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Nome</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Sobrenome</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Contato</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Senha</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Valor/Hora</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Ultimo Acesso</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Email</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Habilitar</th>
                                        <th key={Math.random().toString(36).substring(2, 7)}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData && currentData.length > 0 ? (currentData).map(y => {
                                        return <tr key={Math.random().toString(36).substring(2, 7)}>

                                            <td>

                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.idUsuario}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.Nome}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.Sobrenome}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.Telefone}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.Senha}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.ValorHora}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.UltimoLogin}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Control readOnly style={{ width: 300 }} type="text" placeholder="texto"
                                                    key={Math.random().toString(36).substring(2, 7)} value={y.Email}
                                                    onChange={item => this.setState({ database: item.target.value })} />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={y.Ativo == 'Y' ? true : false}
                                                    key={Math.random().toString(36).substring(2, 7)}
                                                    onChange={item => this.changeUserStatus(y.idUsuario, item.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                <Button variant="primary" onClick={() => { this.edit(y) }}>
                                                    Editar
                                                </Button>
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10, padding: 3 }}>
                        <Col>
                            <Pagination>
                                <Pagination.Prev onClick={prevPage} />
                                <br />
                                {pages}
                                <br />
                                <Pagination.Next onClick={nextPage} />
                            </Pagination>
                        </Col>
                        <Col>
                            <Button variant="primary" href="/">
                                Voltar
                            </Button>
                            <Button variant="success" style={{ marginLeft: 10 }} onClick={() => { this.setState({ modalShow: true }); }}>
                                Adicionar
                            </Button>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </Form>



                <Modal show={this.state.modalShow} onHide={() => { this.setState({ modalShow: false }); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Usuario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control readOnly style={{ marginTop: 10 }} value={this.state.id} type="text" placeholder="Id" />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.userName} type="text" placeholder="Nome" onChange={item => this.setState({ userName: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.sobrenome} type="text" placeholder="Sobrenome" onChange={item => this.setState({ sobrenome: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.tel} type="text" placeholder="Telefone" onChange={item => this.setState({ tel: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.passWord} type="text" placeholder="Senha" onChange={item => this.setState({ passWord: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.hourValue} type="number" placeholder="Valor" onChange={item => this.setState({ hourValue: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.email} type="text" placeholder="Email" onChange={item => this.setState({ email: item.target.value })} />
                        <Form.Select style={{ marginTop: 10 }} value={this.state.tipo} type="text" placeholder="Tipo Acesso" onChange={item => this.setState({ tipo: item.target.value })}>
                            <option value="0">Tipo Acesso</option>
                            <option value="3">Gestor</option>
                            <option value="2">Cadastro</option>
                            <option value="1">Colaborador</option>
                        </Form.Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.cancelEdit() }}>
                            Voltar
                        </Button>
                        <Button variant="success" onClick={() => { this.saveOrUpdate(this.state.id) }}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >

        );
    }
}

export default PartnersManage;