import { request } from '../http';

const patientsService = {
    get: () => {
        return request.get('/patient');
    },
    getById: (id) => {
        return request.get(`/patient/${id}`);
    },
    getByGroup: (id) => {
        return request.get(`/patient/group/${id}`);
    },
    post: (body) => {
        return request.post('/patient', body);
    },
    patch: (body, id) => {
        return request.patch(`/patient/${id}`, body);
    }
    ,
    patchStatus: (body, id) => {
        return request.post(`/patient/${id}`, body);
    }
}

export {
    patientsService
}